<template>
  <div class="strategy">
    <banner></banner>
    <div class="site-content animate">
      <main class="site-main">
        <article class="hentry">
          <!-- 文章头部 -->
          <header class="entry-header">
            <!-- 标题输出 -->
            <h1 class="entry-title">企业愿景</h1>
            <hr />
          </header>
          <!-- 正文输出 -->
          <div class="entry-content">
            <h4 id="好生利赵州饼">好生利赵州饼</h4>
            <p>始终相信好吃就是硬道理</p>
            <P>好生利的目标是生产让人自豪的产品，最好的营销永远是顾客自发的分享</P>
            <P>好生利通过好产品获得盈利</P>
            <P>好生利通过顾客自愿付费来盈利</P>
            <P>好生利将始终坚持这一目标</P>
            <P>赵州饼是好生利最盈利的产品，那时候好生利还叫桥头第一家，桥头第一家就是顾客口口相传的名字，是我们最荣幸的口碑</P>
            <P>希望好生利能够在未来继续传承，成为更多人知道和喜欢的品牌</P>
            <hr />
            <img class="strategyImg" src="https://s4.ax1x.com/2022/02/24/bPxYxx.jpg" alt="">
            <img class="strategyImg" src="https://s4.ax1x.com/2022/02/22/bSixR1.jpg" alt="">
            <img class="strategyImg" src="https://s4.ax1x.com/2022/02/24/bPxJR1.jpg" alt="">
          </div>
        </article>
      </main>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/banner";
export default {
  name: "strategy",
  data() {
    return {
      showDonate: false,
      comments: [],
      menus: [],
    };
  },
  components: {
    Banner,
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>
<style scoped lang="less">
.site-content {
  position: relative;
  .site-main {
    padding: 80px 0 0 0;
  }
}
article.hentry {
  .entry-header {
    .entry-title {
      font-size: 23px;
      font-weight: 600;
      color: #737373;
      margin: 0.67em 0;

      &:before {
        margin-right: 6px;
        color: #d82e16;
        font-size: 20px;
        font-weight: 600;
      }
    }

    hr {
      height: 1px;
      border: 0;
      background: #efefef;
      margin: 15px 0;
    }

    .breadcrumbs {
      font-size: 14px;
      color: #d2d2d2;
      text-decoration: none;
      margin-bottom: 30px;
    }
  }
  .strategyImg{
    width: 33.3%;
  }
}
</style>
